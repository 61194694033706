<template>
  <div>
    <div class="pititle">
      <el-form>
        <div class="litop">
          <el-row>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                <el-button @click="show_add_batch" type="warning"
                  >新建批次</el-button
                >
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="项目名称">
                  <!-- build_company -->
                  <el-input
                    v-model="form.name"
                    placeholder="请输入项目名称"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="测评机构">
                  <el-input
                    v-model="form.test_company"
                    placeholder="请输入测评机构"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="批次编号">
                  <el-input
                    v-model="form.batch"
                    placeholder="请输入批次编号"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="建筑类型">
                  <el-select
                    v-model="form.building_type"
                    placeholder="建筑类型"
                    @blur="saveSearchInSession"
                  >
                    <el-option :value="1" label="公共建筑"></el-option>
                    <el-option :value="2" label="居住建筑"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple-light">
                <el-button type="primary" @click="search">查询</el-button>
                <span>共{{ totalCount }}条</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="piconent">
      <!-- 表格 -->
      <div class="pitable">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            label="批次编号"
            prop="batch"
            width="180"
          >
          </el-table-column>

          <el-table-column
            align="center"
            label="项目名称"
            prop="name"
          >
            <template slot-scope="{ row }">
              <div  @click="gotoStepInformation(row)" class="detailPush">
              <span>{{row.name}}</span>
                </div>
            </template> 
          </el-table-column>

          <el-table-column align="center" label="建筑类型" width="180">
            <template slot-scope="{ row }">
              <span v-if="row.building_type == 1">公共建筑</span>
              <span v-if="row.building_type == 2">居住建筑</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="项目城市"
            prop="city"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="测评机构"
            prop="test_company"
          >
          <template slot-scope="{ row }">
                  <span v-if="row.fname">{{row.fname}}</span>
                  <span v-else>{{row.test_company}}</span>
                </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="测评星级"
            prop="stars"
            width="180"
          >
            <template slot-scope="{ row }">
              <i
                v-for="item in row.stars"
                :key="item"
                class="el-icon-star-on"
              ></i>
            </template>
          </el-table-column>
          <el-table-column align="center" label="标识阶段" width="180">
            <template slot-scope="{ row }">
              <span v-if="row.stage == 1">能效测评</span>
              <span v-else-if="row.stage == 2">实测评估</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="申报时间" prop="created_at" width="180">
            <template slot-scope="{ row }">
              <span>{{
                row.created_at | formatDate("yyyy-MM-dd")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="项目状态" width="180">
            <template slot-scope="{ row }">
              <div @click="gotoStepInformation(row)" class="detailPush">
                <span v-if="row.status == 1">未提交</span>
                <span v-else-if="row.status == 2" style="color:#E6A23C !important">形式初审</span>
                <span v-else-if="row.status == 3" style="color:#E6A23C !important">形式复审</span>
                <span v-else-if="row.status == 4">审查分配</span>
                <span v-else-if="row.status == 5">专家初审</span>
                <span v-else-if="row.status == 6">专家复审</span>
                <span v-else-if="row.status == 7">专家反馈</span>
                <span v-else-if="row.status == 8">审核通过</span>
                <span v-else-if="row.status == 9">待公示</span>
                <span v-else-if="row.status == 10">公示中</span>
                <span v-else-if="row.status == 11">公告中</span>
                <span v-else-if="row.status == 12">待寄送</span>
                <span v-else-if="row.status == 13">待自取</span>
                <span v-else-if="row.status == 14">已完成</span>
                <span v-else-if="row.status == 15">形式审查待修改</span>
                <span v-else-if="row.status == 16">形式审查已修改</span>
                <span v-else-if="row.status == 17">技术审查待修改</span>
                <span v-else-if="row.status == 18">技术审查已修改</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
 <!-- 分页 -->
      <div class="pageTotal">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-count="totalPage"
            :page-size="limit"
            :total="totalCount"
            background
            layout=" prev, pager, next, total, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    <el-dialog :visible.sync="batch_show" title="输入批次号" width="30%">
      <el-input v-model="batch" placeholder="请输入批次号"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batch_show = false">取 消</el-button>
        <el-button type="primary" @click="add_batch">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { delProject, getProjectList, addBatch } from "../../../../api/project";

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      batch: "",
      batch_show: false,
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      checked_list: [],
    };
  },
  mounted() {
     this.getSearchInSession();
    this.getList();
  },
  methods: {
     saveSearchInSession() {
      if (this.search_form) {
        sessionStorage.setItem(
          "formalIpend_search",
          JSON.stringify(this.search_form)
        );
      }
    },
    getSearchInSession() {
      let searchForm = sessionStorage.getItem("formalIpend_search");
      if (searchForm && searchForm != "") {
        this.search_form = JSON.parse(searchForm);
      }
    },
    //进入审核页面 要先判断是否建立了批次
    gotoStepInformation(item){
      if (item.batch && item.batch!=''){
        this.$router.push({
          path: '/formal/stepInformation',
          query: {
            id: item.id
          }
        })
      }else {
        this.$message.warning('请先建立批次');
      }
    },

    show_add_batch() {
      if (this.checked_list.length > 0) {
        this.batch_show = true;
      } else {
        this.$message.warning("请选择项目");
      }
    },

    add_batch() {
      let that = this;
      let ids = this.checked_list.map((item) => {
        return item.id;
      });
      if (this.batch && this.batch != "") {
        let params = {
          ids: ids,
          batch: this.batch,
          token: this.$store.state.token,
        };
        addBatch(params).then((res) => {
          console.log(res);
          that.batch_show = false;
          that.getList();
        });
      } else {
        this.$message.warning("请填写批次号");
      }
    },

    handleSelectionChange(val) {
      this.checked_list = val;
    },

    //删除数据
    check_del() {
      let that = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = this.checked_list.map((item) => {
            return item.id;
          });
          // console.log(ids);
          let params = {
            token: this.$store.state.token,
            id: ids,
          };
          delProject(params).then((res) => {
            console.log(res);
            that.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //获得表格数据
    getList() {
      let that = this;

      let params = this.form;
      params.page = this.page;
      params.limit = this.limit;
      //2:形式初审 3:形式复审 4:审查分配,15:形审待修改
      params.status = "2,3,15";
      params.token = this.$store.state.token;
      params.build_company = this.name;

      getProjectList(params)
        .then((res) => {
          // console.log(res);
          that.tableData = res.data;
          that.page = res.page;
          that.totalCount = res.totalCount;
          that.totalPage = res.totalPage;
           if (!that.tableData || that.tableData.length == 0) {
            this.$message.warning("暂无数据");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },

    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getList();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style scoped>
/deep/.el-dialog__headerbtn .el-dialog__close{
  color: #ffffff;
}
/deep/.el-dialog__title{
color: #ffffff;
}
/deep/.el-dialog{
  border-radius: 10px;
}
/deep/.el-dialog__header{
  background-color: #3086fb;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}
/deep/ .el-form-item__label {
  font-size: 18px;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 255px;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #3086fb;
  font-weight: bold;
  font-size: 18px;
}
.detailPush {
  color: #3086fb;
  text-decoration: none;
  cursor: pointer;
}

/* .pitable {
  height: 60vh;
} */

.litop {
  margin: 20px 24px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.pageTotal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 38px 0;
}
.pititle{
      padding: 30px 0;
}
/* .piconent{
  height: 80vh;
} */
</style>
